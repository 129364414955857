import React from "react"

import { withCorporateJoin } from "./withCorporateJoin"

export const CorporateJoin = withCorporateJoin(
  ({ title, content }: Props): JSX.Element => <></>
)

export interface Props {
  title?: string
  content?: any
}
