import React, { useMemo, useEffect } from "react"

import { useCore } from "../../hooks/useCore"
import { useAnalytics } from "../../hooks/useAnalytics"
import { useStripe } from "../../hooks/useStripe"

export const withCorporateJoin = Component =>
  React.memo(({ name = "CorporateJoin", page, location }) => {
    const {
      helpers: { sanityContent },
    } = useCore()
    const { openCorporateCheckout, loading } = useStripe()
    const { trackCustomEvent } = useAnalytics()

    const params = new URLSearchParams(location?.search)
    const id = params.get("id") || false
    const plan = params.get("plan") || false
    const email = params.get("email") || false
    const code = params.get("code") || false

    useEffect(() => {
      if (!loading) {
        trackCustomEvent("corporateJoin", { plan })
        openCorporateCheckout(email, id, plan, code)
      }
    }, [])

    const title = page?.title
    const content = sanityContent(page?.content)

    Component.displayName = name
    return useMemo(() => <Component title={title} content={content} />, [])
  })
